@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}

/* transition for hide/show transform dropdown */
.dropdown-enter {
    opacity: 0.01;
    transform: translateY(-10px);
}

.dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms, transform 200ms;
}

.dropdown-exit {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-exit-active {
    opacity: 0.01;
    transform: translateY(-10px);
    transition: opacity 200ms, transform 200ms;
}

/* Transition for the items in the list */
.item-enter {
    opacity: 0.01;
    transform: translateY(-20px);
    max-height: 0px; /* Initialize max-height for a smooth enter transition */
    /* overflow: hidden; */
}

.item-enter-active {
    opacity: 1;
    transform: translateY(0);
    max-height: 500px; /* Adjust based on the maximum expected height of the content */
    transition: opacity 250ms, transform 250ms, max-height 250ms;
    /* overflow: hidden; */
}

.item-exit {
    opacity: 1;
    transform: translateY(0);
    max-height: 500px; /* Ensure it matches the max-height in enter-active for consistency */
    /* overflow: hidden; */
}

.item-exit-active {
    opacity: 0.01;
    /* transform: translateY(-20px); */
    max-height: 0px; /* Transition to no vertical height */
    transition: opacity 250ms, transform 250ms, max-height 250ms;
    /* overflow: hidden; */
}

/* styles for pretty representation */
.formatted-representation>* {
    padding: 0.5rem;
}

/* scrollbar */
/* Add this to your stylesheet */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}